import * as React from "react"
import { graphql } from 'gatsby'
import ComponentBlog from "./../components/loadable/blog";

const Component = (props) => {
    return (
        <ComponentBlog {...props} />
    )
};

export default Component;

let limitNumberOfArticles = 10000;
if (process.env.NODE_ENV !== 'production') {
    limitNumberOfArticles = 5;
}

export const query = graphql`
    query Blog {

        prismicContact {
            id
            data {
              first_meeting_is_free
              therapeut_name {
                text
              }
              body {
                ... on PrismicContactDataBodyEmail {
                  id
                  items {
                    display
                    email {
                      text
                    }
                  }
                  slice_label
                  slice_type
                }
                ... on PrismicContactDataBodyLocation {
                  id
                  items {
                    display
                    embeded_map {
                     raw
                    }
                    short_name {
                      text
                    }
                    address {
                      text
                    }
                    latitude {
                      text
                    }
                    longitude {
                      text
                    }
                    image_building {
                      gatsbyImageData
                      alt
                      url
                    }
                  }
                  slice_label
                  slice_type
                }
                ... on PrismicContactDataBodyPhone {
                  id
                  items {
                    display
                    phone_number {
                      text
                    }
                  }
                  slice_label
                  slice_type
                }
                ... on PrismicContactDataBodyProgram {
                  id
                  primary {
                    program {
                      text
                    }
                  }
                  slice_label
                  slice_type
                }
                ... on PrismicContactDataBodyWhasup {
                  id
                  items {
                    display
                    whatsup_phone_number {
                      text
                    }
                    whatsup_desktop_text {
                      text
                    }
                    whatsup_mobile_text {
                      text
                    }
                  }
                  slice_label
                  slice_type
                }
              }
              page_title {
                text
              }
              second_title {
                text
              }
              text_first_meeting_free {
                text
              }
            }
        }
       
        prismicHomepage {
            id
            data {
                header_category {
                    text
                    html
                }
                header_my_name {
                    text
                    html
                }
                header_phone {
                    text
                    html
                }
                header_program {
                    text
                    html
                }
                banner_category {
                    text
                    html
                }
            }
        }
        allPrismicBlogArticle(sort: {order: DESC, fields: first_publication_date}, limit: 5000) {
            edges {
                node {
                    id
                    data {
                        article_description {
                            text
                            html
                        }
                        article_main_image {
                            alt
                            copyright
                            url
                            gatsbyImageData
                        }
                        article_title {
                            text
                            html
                        }
                    }
                }
            }
        }
    }
`
