import * as React from "react"
import {GatsbyImage} from 'gatsby-plugin-image'
import {Grid} from "@material-ui/core";
import MainMenu from "../MainMenu";
import "./../../styles/index.scss"
import "./../../styles/general.scss"
import Seo from "../seo";
import "./../../styles/blog.scss"
import {slugify} from "../../lib/app_string";
import { Link } from "gatsby"
import AppFooter from "../AppFooter";
import {Mixpanel} from "../Mixpanel";
import {useEffect, useMemo} from "react";
import ProfileSecondaryImage from "../ProfileSecondaryImage";
import OpenChat from "../OpenChat";
import Banner from "../Banner";

export default function ComponentBlog ({data}) {
    const prismicContact = data.prismicContact
    const homepageData = data.prismicHomepage.data
    const allPrismicBlogArticle = data.allPrismicBlogArticle.edges

    useEffect(() => {
        Mixpanel.track('Page-Blog');
    }, [data])

    const parseDirtyHtml = (dirtyString) => {
        // const parser = new DOMParser();
        // const document = parser.parseFromString(dirtyString, 'text/html');
        // return document.textContent || '';
        return dirtyString
    }

    const seoData = {
        canonical: "https://www.cabinet-psiho.ro/blog/",
        title: 'Blog - Cabinet psihologic sector 6',
        description: 'Blog - Cabinet psihologic Roxana Simionescu, Bucuresti - psiholog atestat, articole avize medicale, angajare, profil psihologic clinica medicala, pshioterapie',
        url:  "https://www.cabinet-psiho.ro/blog/",
        metas: [
        ]
    }

    const renderUsingMemo = () => (
        <div >
            <Seo title="Blog" seoData={seoData}/>
            <Grid container>
                <MainMenu pageName={"blog"} prismicContact={prismicContact} />

                <Banner />

                <Grid item sm={12} xl={12} lg={12} md={12}>
                    <div className={"w-full flex flex-column justify-content-center align-items-center align-middle"}>
                        <div className={"w-full flex flex-row justify-content-center align-middle align-content-center blog_header"}>
                            <h1 className={"font_size_xl"}>Roxana Simionescu - Blog</h1>
                        </div>
                        {allPrismicBlogArticle.map((article, k) => {
                            return (
                                <div className={"w-full flex flex-row justify-content-center align-middle align-items-start blog_item flex-wrap"} key={k}>
                                    <div>
                                        <GatsbyImage image={article.node.data.article_main_image.gatsbyImageData} className={"blog_item_image"} alt={article.node.data.article_title.text}/>
                                    </div>
                                    <div className={"flex flex-column justify-content-start align-middle align-items-start"}>
                                        <div className={"blog_title font-bold maximum_box_size"}>
                                            <Link to={"/blogpost/" + slugify(article.node.data.article_title.text)}>
                                                {article.node.data.article_title.text}
                                            </Link>
                                        </div>
                                        <div
                                            className={'maximum_box_size'}
                                            dangerouslySetInnerHTML={{__html: parseDirtyHtml(article.node.data.article_description.html).substring(0, 200) + "..."}}
                                        >
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    <div className={"w-full flex flex-row justify-content-center align-items-center align-middle mt-16"}>
                        <OpenChat />
                    </div>

                    <div className={"w-full flex flex-row justify-content-center align-items-center align-middle mt-16 mb-16"}>
                        <ProfileSecondaryImage width={70}/>
                        <div className={"ml-8"}>Autor:&nbsp;&nbsp;&nbsp;&nbsp;<b>{homepageData.header_my_name.text}</b>, Psiholog, Terapeut, Dezvoltare Personala</div>
                    </div>
                </Grid>
                <AppFooter prismicContact={prismicContact}/>
            </Grid>
        </div>
    )

    const memoizedValue = useMemo(() => renderUsingMemo(), [homepageData]);
    return (<>{memoizedValue}</>)
}
